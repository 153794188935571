import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import styles from "../../jss/landing-webdesign.js"
// import mobileWeb from "../../public/mobile-web-developers-toronto.svg";

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "web-designer-toronto.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div
      className={classNames(classes.section, classes.sectionDark)}
      style={{ marginBottom: "80px" }}
    >
      <Grid container justify="center" alignItems="center" spacing={4}>
        <Grid item md={5} sm={5} xs={10} lg={4}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Toronto-Web-Designer"
          />
        </Grid>
        <Grid item md={8} sm={8} lg={4} xs={11} sm={11}>
          <div>
            <h3 className={classes.title}>We Develop SEO-Friendly Websites</h3>
            <h6 className={classes.description}>Web Designer Toronto</h6>
            <h5 className={classes.description}>
              Our team has extensive experience with building websites that are
              optimized for more than just desktop computers. The majority of
              web traffic comes from mobile devices and we can help you optimize
              for them. Depending on the size of the device's screen our
              websites will automatically present the information you want in an
              optimal matter.
            </h5>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
