import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import styles from "../jss/landing-webdesign.js"
import classNames from "classnames"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"
import GridContainer from "../components/Grid/GridContainer.js"
import GridItem from "../components/Grid/GridItem.js"
import HeaderLinks from "../components/Header/HeaderLinks.jsx"
import Parallax from "../components/Parallax/ParallaxMaintainence.jsx"

import Marketing from "../pages-sections/Maintenance/Marketing.jsx"

import Intro from "../pages-sections/Maintenance/Intro.jsx"

import Contact from "../pages-sections/Maintenance/Contact.jsx"
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

const useStyles = makeStyles(styles)

export default function Maintenance(props) {
  const classes = useStyles()
  const { ...rest } = props
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title=" Web designer Toronto | Vancouver Web Designer | Atlas Agency"
        description="Web designer in Toronto and Vancouver. We provide web designer services for professionals in Toronto and Vancouver ."
        canonical="https://atlasagency.ca/web-designer-toronto/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        brand="Atlas Agency"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 60,
          color: "dark",
        }}
        {...rest}
      />

      <Parallax className={classes.parallax} filter responsive>
        <div className={classes.container} style={{ marginTop: "40px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Website Maintenance</h2>
              <h4>
                Purchase credits that can be used towards various services that
                will improve your website.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ overflowX: "hidden" }}
      >
        <div className={classes.container}>
          <Intro />
        </div>
        <Marketing />

        <div className={classes.container}>
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
