import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import SpellcheckIcon from "@material-ui/icons/Spellcheck"

import CodeIcon from "@material-ui/icons/Create"

import LinkIcon from "@material-ui/icons/Loop"
import styles from "../../jss/intro.js"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item xs={11} sm={11} md={8}>
          <h1 className={classes.title}>Web Designer Toronto</h1>
          <h5 className={classes.description}>
            Make lasting web changes with an experienced team of developers. We
            can help you improve your content, write a blog or help you SEO
            optimize your website.
          </h5>
        </Grid>
      </Grid>
      <div style={{ marginTop: 60 }}>
        <Grid container>
          <Grid item xs={11} sm={11} md={4}>
            <InfoArea
              title="Website Updates"
              description="We can help you update your existing website. We can help with Wordpress, Squarespace or custom coded websites"
              icon={LinkIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={11} sm={11} md={4}>
            <InfoArea
              title="SEO Keyword Planning"
              description="Keyword planning is an essential step for ranking on Google's search engine. We will research and obtain the highest searched keywords for your business. "
              icon={SpellcheckIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={11} sm={11} md={4}>
            <InfoArea
              title="Content Creation"
              description="Using the keywords identified during the research phase, our developers will optimize your web page to include keyword META tags throughout your pages."
              icon={CodeIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
